import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlistCss32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M21.3 5.5c2.3 0 4.4 1 5.9 2.8 2.1 2.6 2.3 6.5.5 9.6-3.3 5.2-7.4 7.7-11.2 8.7H15.4c-3.8-1-7.9-3.5-11.2-8.7-1.8-3.1-1.7-7 .5-9.6 1.5-1.7 3.8-2.7 5.9-2.7 2 0 3.9.7 5.4 2 1.5-1.4 3.4-2.1 5.3-2.1" className="wishlist-css-32_svg__fill" /><path d="M10.7 7C9 7 7.1 7.9 5.9 9.2c-1.7 2.1-1.9 5.3-.3 7.9 3.9 6.2 8.9 7.7 10.3 8h.3c1.4-.3 6.3-1.9 10.3-8 1.5-2.6 1.4-5.8-.3-7.9-1.3-1.3-3-2.2-4.9-2.2-1.7 0-3.3.7-4.5 1.9l-.2.2c-.3.3-.9.3-1.2 0l-.2-.2C14.1 7.7 12.4 7 10.7 7m10.6-1.5c2.3 0 4.4 1 5.9 2.8 2.1 2.6 2.3 6.5.5 9.6-3.3 5.2-7.4 7.7-11.2 8.7H15.4c-3.8-1-7.9-3.5-11.2-8.7-1.8-3.1-1.7-7 .5-9.6 1.5-1.7 3.8-2.7 5.9-2.7 2 0 3.9.7 5.4 2 1.5-1.4 3.4-2.1 5.3-2.1" className="wishlist-css-32_svg__border" /></SvgIcon>;
};
const Memo = memo(SvgWishlistCss32);
export default Memo;