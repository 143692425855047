import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCheckmark32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M3.726 20.052s3.552 4.296 5.813 9.665c.968.714 2.483.068 4.2-2.506 2.058-3.09 12.915-22.196 16.143-23.627 3.229-1.432-1.393-2.726-4.039 0-3.391 3.489-11.139 13.29-12.833 15.706-1.695 2.417-1.937 3.088-2.542 2.014-.605-1.073-1.816-3.893-2.663-4.162-.848-.267-.969 0-2.059.673-1.092.669-4.159 2.058-2.02 2.237" /></SvgIcon>;
};
const Memo = memo(SvgCheckmark32);
export default Memo;